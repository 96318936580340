export class StorageService {
  private storage: Storage;
  constructor() {
    this.storage = new Storage(); // or some StorageConfig instead of null
  }

  setItem(key, value) {
    this.storage.setItem(key, value);
  }

  getItem(key) {
    this.storage.getItem(key);
  }
}
