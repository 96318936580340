import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import * as io from 'socket.io-client';
import { Socket, SocketIOClient } from 'socket.io-client';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  socket: Socket;

  // uri: string = 'https://constructshareapi.herokuapp.com';
  // uri: string = 'https://constructshareapidev.herokuapp.com';
  // uri: string = 'http://18.218.252.168:3000';
  uri: string = environment.socketBaseURL;

  constructor() {
    if (window.location.origin.startsWith('https://')) {
      this.uri = environment.socketBaseURL.replace('http://', 'https://');
    }

    this.socket = io(this.uri);
    // this.socket(this.uri);

    // this.socket.on(eventName, (data) => {
    //   subscriber.next(data);
    // }
    // this.listen("connection")

    // this.socket.emit('join', room);
    let s = this;

    // this.socket.on('connection', ()=> {
    //   console.log('connected');
    //   this.socket.on('join', (room) => {
    //     console.log('enter');
    //     s.socket.join(room);
    //   });
    // })
    // this.socket.emit('join', 'something');
    // this.createRoom();
  }

  //  this.socket.on()

  createRoom(room) {
    this.socket.removeAllListeners();
    this.socket.emit('join', room);
  }

  listen(eventName: string) {
    return new Observable((subscriber) => {
      this.socket.on(eventName, (data) => {
        subscriber.next(data);
      });
    });
  }

  emit(eventName: string, data?) {
    this.socket.emit(eventName, data);
  }

  // connectSocket() {
  //   // console.log("connecting socket..:", localStorage.getItem("_id"));
  //   // this.socket = io("http://localhost:8000/", {
  //   //   forceNew: true
  //   // });
  //   this.socket = io("https://constructshareapi.herokuapp.com/", {
  //     forceNew: true
  //   });
  //   if (this.socket.disconnected) {
  //     this.socket.connect();
  //     // this.addHandlers();
  //   }
  // }
}
