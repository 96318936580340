import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { AuthGuard } from './guards/auth.guard';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { LoginComponent } from './login/login.component';
import { PushInfoComponent } from './push-info/push-info.component';
import { BuyerRequestComponent } from './buyer-request/buyer-request.component';
import { BuyerRequestEditComponent } from './buyer-request/edit/buyer-request-edit.component';
import { WebRequestComponent } from './web-request/web-request.component';
import { PushBannerComponent } from './push-banner/push-banner.component';
// import { UserJobsComponent } from './user-jobs/user-jobs.component';
export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
    data: { title: 'Login' },
  },
  // {
  //   path: "forgot-password",
  //   loadChildren:
  //     "./forgot-password/forgot-password.module#ForgotPasswordModule",
  // },

  {
    path: '',
    component: FullComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: './dashboard/dashboard.module#DashboardModule',
      },
      {
        path: 'user',
        loadChildren: './user/user.module#UserModule',
      },
      {
        path: 'coworkers',
        loadChildren: './coworkers/coworkers.module#CoworkersModule',
      },
      {
        path: 'product',
        loadChildren: './product/product.module#ProductModule',
      },
      {
        path: 'orders',
        loadChildren: './orders/orders.module#OrdersModule',
      },
      {
        path: 'icons',
        loadChildren: './icons/mat-icon.module#IconsModule',
      },
      {
        path: 'email-template',
        loadChildren: './email-template/email-template.module#EmailTemplateModule',
      },

      {
        path: 'chat',
        loadChildren: './chat/chat.module#ChatModule',
      },
      {
        path: 'payment',
        loadChildren: './payment/payment.module#PaymentModule',
      },
      {
        path: 'review-rating',
        loadChildren: './review-rating/review-rating.module#ReviewRatingModule',
      },
      {
        path: 'performance',
        loadChildren: './performance/performance.module#PerformanceModule',
      },
      {
        path: 'notification',
        loadChildren: './notification/notification.module#NotificationModule',
      },
      {
        path: 'support-system',
        loadChildren: './support-system/support-system.module#SupportSystemModule',
      },

      {
        path: 'category',
        loadChildren: './category/category.module#CategoryModule',
      },
      {
        path: 'audit-log',
        loadChildren: './audit-log/audit-log.module#AuditLogModule',
      },
      {
        path: 'settings',
        loadChildren: './app-setting/app-setting.module#AppSettingModule',
      },
      {
        path: 'interest',
        loadChildren: './intrest/intrest.module#IntrestModule',
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent,
      },
      {
        path: 'push-info',
        component: PushInfoComponent,
      },
      {
        path: 'buyer-request',
        component: BuyerRequestComponent,
      },
      {
        path: 'web-request',
        component: WebRequestComponent,
      },
      
      {
        path: 'buyer-request/edit/:id',
        component: BuyerRequestEditComponent,
      },
      {
        path: 'push-banner',
        component:PushBannerComponent,
      }
    ],
  },
];
