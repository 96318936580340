import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';
import { MessageService } from './services/message.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  loader: boolean = false;

  constructor(private authService: AuthService, public messageService: MessageService) {
    this.authService.getLoader().subscribe((value) => {
      setTimeout(() => {
        this.loader = value;
      }, 100);
    });
    let getToken = localStorage.getItem('token');
    if (getToken) {
      this.authService.token = getToken;
    }
  }
}
