import * as $ from 'jquery';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';

import { FlexLayoutModule } from '@angular/flex-layout';
import { FullComponent } from './layouts/full/full.component';
import { AppHeaderComponent } from './layouts/full/header/header.component';
import { AppSidebarComponent } from './layouts/full/sidebar/sidebar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DemoMaterialModule } from './demo-material-module';

import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner.component';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AuthGuard } from '../app/guards/auth.guard';
import { JwtModule } from '@auth0/angular-jwt';
import { ToastrModule } from 'ngx-toastr';
import { StorageModule } from '@ngx-pwa/local-storage';
import { ApiLoaderComponent } from './api-loader/api-loader.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatDialogModule } from '@angular/material';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { SocketService } from './services/socket.service';
import { UtilService } from './utils/util.service';
import { StorageService } from './services/storage.service';
import { MessageService } from './services/message.service';
import { PushInfoComponent } from './push-info/push-info.component';
import { BuyerRequestComponent } from './buyer-request/buyer-request.component';
import { BuyerRequestEditComponent } from './buyer-request/edit/buyer-request-edit.component';
import { WebRequestComponent } from './web-request/web-request.component';
import { PushBannerComponent } from './push-banner/push-banner.component';
import { PushBannerDeleteDialogboxComponent } from './push-banner/push-banner-delete-dialogbox/push-banner-delete-dialogbox.component';

export function tokenGetter() {
  return localStorage.getItem('token');
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true,
};

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    LoginComponent,
    AppHeaderComponent,
    SpinnerComponent,
    AppSidebarComponent,
    ResetPasswordComponent,
    PushInfoComponent,
    BuyerRequestComponent,
    BuyerRequestEditComponent,
    ApiLoaderComponent,
    AppBlankComponent,
    WebRequestComponent,
    PushBannerComponent,
    PushBannerDeleteDialogboxComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
      preventDuplicates: false,
    }),
    DemoMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    FlexLayoutModule,
    ImageCropperModule,
    PerfectScrollbarModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(AppRoutes),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ['constructshare.com'],
        blacklistedRoutes: ['http://constructshare.com/constructshare_backend/public/api/login'],
      },
    }),
    StorageModule.forRoot({ IDBNoWrap: true }),
  ],
  providers: [
    AuthGuard,
    // LocalStorageService,
    StorageService,
    SocketService,
    UtilService,
    // MessageService,
  ],
  bootstrap: [AppComponent],
  entryComponents: [PushBannerDeleteDialogboxComponent],
})
export class AppModule {}
