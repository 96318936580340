import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material'
import { AuthService } from '../services/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  // encapsulation:ViewEncapsulation.None
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  loginFormErrors: any;

  constructor(private router: Router, private authService: AuthService, private formBuilder: FormBuilder, private toast : ToastrService) {
    this.loginFormErrors = {
      email: {},
      password: {}
    };
  } 

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });

    this.loginForm.valueChanges.subscribe(() => {
      this.onLoginFormValuesChanged(); 
    });
  }

  onSubmit(data) {
   
    // if (data.email == 'admin@construct.com' && data.password == '123456') {
    this.authService.setLoader(true);
    let Obj = {
      email : data.email,
      password : data.password,
      userRole: 'admin'
    }
    // const body = new URLSearchParams();
    // body.set('email', data.email);
    // body.set('password', data.password);
    // localStorage.setItem('token', '123456');
    // this.router.navigateByUrl('/dashboard');
    this.authService.postData('user/login', Obj).subscribe(response => {
      this.authService.setLoader(false);
      if (response.data) {
        if(response.data.user.userRole === 'admin') {
          localStorage.setItem('token', response.token);
          localStorage.setItem('email', response.data.user.email);
          let Obj = {
            name : response.data.user.name,
            email : response.data.user.email,
            id: response.data.user._id
          }
          localStorage.setItem('adminName', JSON.stringify(Obj));
          // localStorage.setItem('userIdAdmin', res.id);
          // localStorage.setItem('firstName', res.first_name);
          // localStorage.setItem('pass', data.password);
          this.router.navigateByUrl('/dashboard');
          let getToken = localStorage.getItem('token')
          if(getToken){
            this.authService.token = getToken;
          }
        }  
        else {
          this.toast.error('You are not authorised');
        }     
      }
    }, error => {
        this.authService.setLoader(false);
        this.toast.error(error);
      });
    // } 
    // else {
    //   this.toast.error('User is not Authenticated');
    // }  
}

onLoginFormValuesChanged() {
  for (const field in this.loginFormErrors) {
    if (!this.loginFormErrors.hasOwnProperty(field)) {
      continue;
    }

    // Clear previous errors
    this.loginFormErrors[field] = {};

    // Get the control
    const control = this.loginForm.get(field);

    if (control && control.dirty && !control.valid) {
      this.loginFormErrors[field] = control.errors;
    }
  }
}
}

