import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, throwError, Subject } from "rxjs";
import { catchError } from "rxjs/operators";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Router } from "@angular/router";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { BehaviorSubject } from "rxjs";
import { environment } from "../../environments/environment";

const jwtHelper = new JwtHelperService();

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private userData = new Subject<any>();
  private userId = new BehaviorSubject<any>({});
  userData$ = this.userData.asObservable();
  loader = new BehaviorSubject(false);
  localDataValuesChange: Subject<any> = new Subject<any>();
  private second = new Subject<any>();
  second$ = this.second.asObservable();

  // baseUrl : string = 'http://themilkmission.com/milkmission_backend/public/api/';
  // baseUrl : string = 'https://constructshareapi.herokuapp.com/api/v1/'; //local
  // baseUrl : string = 'https://constructshare-dev.herokuapp.com/api/v1/';
  // baseUrl : string = 'http://3.21.247.68:5000';
  // baseUrl: string = 'http://18.138.103.239:5000/api/v1/' // Aws APi Link
  baseUrl: string = environment.apiBaseURL; // Aws APi Link
  token;

  constructor(private http: HttpClient, private router: Router) {
    if (window.location.origin.startsWith("https://")) {
      this.baseUrl = environment.apiBaseURL.replace("http://", "https://");
    }
  }

  setHeaders() {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("Accept", "application/json");
    headers = headers.set("Content-Type", "application/json");
    const token = "Bearer" + " " + this.token;
    headers = headers.set("Authorization", token);
    // headers = headers.set('Bearer Token', this.token);
    return headers;
  }

  setHeadersUploadFile() {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("Accept", "application/json");
    headers = headers.set("enctype", "multipart/form-data");
    const token = "Bearer" + " " + this.token;
    headers = headers.set("Authorization", token);
    // headers = headers.set('Bearer Token', this.token);
    return headers;
  }

  setUserId(data) {
    this.userId.next(data);
  }

  getUserId() {
    return this.userId.asObservable();
  }

  sendId(data) {
    this.second.next(data);
  }

  sendUserData(data) {
    this.userData.next(data);
  }

  public getData = (url, data?: any): Observable<any> => {
    const headers = this.setHeaders();
    const params = {};
    if (data) {
      Object.keys(data).map((key) => {
        if (data[key]) {
          params[key] = data[key];
        }
      });
    }
    const options = {
      headers: headers,
      params: params,
    };

    return this.http
      .get(this.baseUrl + url, options)
      .map(this.extractData)
      .catch(this.handleError);
  };

  // public postLoginData(url, data?: any, type?: string): Observable<any> {
  //   const headers = this.setHeaders();
  //   /* if (type === 'reset') {
  //     headers = headers.set('Authorization', 'Bearer 12344' );
  //   } */
  //   // headers = headers.set('Authorization', 'Bearer' + ' ' + this.token);
  //   const options = { headers: headers };
  //   return this.http.post(this.baseUrl + url, data, options).map(this.extractData).catch(this.handleError);
  // }

  public patchData(url, data?: any, type?: string): Observable<any> {
    const headers = this.setHeaders();
    /* if (type === 'reset') {
      headers = headers.set('Authorization', 'Bearer 12344' ); 
    } */
    // headers = headers.set('Authorization', 'Bearer' + ' ' + this.token);
    const options = { headers: headers };
    return this.http
      .patch(this.baseUrl + url, data, options)
      .map(this.extractData)
      .catch(this.handleError);
  }

  public putData(url, data?: any, type?: string): Observable<any> {
    const headers = this.setHeaders();
    /* if (type === 'reset') {
      headers = headers.set('Authorization', 'Bearer 12344' ); 
    } */
    // headers = headers.set('Authorization', 'Bearer' + ' ' + this.token);
    const options = { headers: headers };
    return this.http
      .put(this.baseUrl + url, data, options)
      .map(this.extractData)
      .catch(this.handleError);
  }

  public postData(url, data?: any, type?: string): Observable<any> {
    const headers = this.setHeaders();
    // headers.set('content-type', 'multipart/form-data' );
    /* if (type === 'reset') {
      headers = headers.set('Authorization', 'Bearer 12344' ); 
    } */
    // headers = headers.set('Authorization', 'Bearer' + ' ' + this.token);
    const options = { headers: headers };
    return this.http
      .post(this.baseUrl + url, data, options)
      .map(this.extractData)
      .catch(this.handleError);
  }
  public uploadFile(url, data?: any, type?: string): Observable<any> {
    // console.log(data.get('file') , 'my working data')
    const headers = this.setHeadersUploadFile();

    const options = { headers: headers };
    return this.http
      .post(this.baseUrl + url, data, options)
      .map(this.extractData)
      .catch(this.handleError);
  }

  // public removeFile(url, data?: string): Observable<any> {
  //   // console.log(data.get('file') , 'my working data')
  //   const headers = this.setHeaders();

  //   const options = { headers: headers };
  //   return this.http
  //     .delete(this.baseUrl + url+'?url='+data, options)
  //     .map(this.extractData)
  //     .catch(this.handleError);
  // }

  public postPublishData(url, data?: any): Observable<any> {
    // const headers = this.setHeaders();

    // headers.set('Content-Type', 'application/x-www-form-urlencoded' );
    const options = {
      headers: new HttpHeaders()
        .set("Content-Type", "application/x-www-form-urlencoded")
        .set("auth", environment.featuredListingTOKEN),
    };
    return this.http.post(url, data, { ...options, responseType: "text" });
  }
  // public login() {
  //   return this.http.post(this.baseUrl + '/api/v1/login')
  // }

  public deleteData(url): Observable<any> {
    const headers = this.setHeaders();
    const options = { headers: headers };
    return this.http
      .delete(this.baseUrl + url, options)
      .map(this.extractData)
      .catch(this.handleError);
  }

  private extractData = (res: Response) => {
    this.setLoader(false);
    const body = res;
    return body || {};
  };

  setLoader(value) {
    this.loader.next(value);
  }

  private handleError = (error: any) => {
    const errMsg = error.error;
    this.setLoader(false);
    if (typeof errMsg.message === "object") {
      const arr = Array.from(
        Object.keys(errMsg.message),
        (k) => errMsg.message[k]
      );
      return throwError(arr);
    }
    return throwError(errMsg.message);
  };

  getLoader() {
    return this.loader.asObservable();
  }

  setUserData(value) {
    this.userData.next(value);
  }

  getUserData() {
    return this.userData.asObservable();
  }
}
