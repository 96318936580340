import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class MessageService {
  public subject = new Subject<{ topic: string; data: any }>();
  onMessage = this.subject.asObservable();

  constructor(public authService: AuthService) {}

  sendMessage(topic: string, data: any) {
    this.subject.next({ topic, data });
  }

  clearMessages() {
    this.subject.next();
  }
}
