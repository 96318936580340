import { Injectable } from '@angular/core';
import { get } from 'lodash';
import { Mixin } from './mixin';

@Injectable()
export class UtilService extends Mixin {
  scrollTo(containerId, itemId) {
    let x = document.getElementById(containerId);
    const elem = document.getElementById(itemId);

    let index = 0;
    const nodes = x.childNodes || [];

    console.log(nodes.length)
    nodes.forEach(function (node, i) {
      console.log(node, node.id, itemId)
      if (node.id === itemId) {
        index = i;
      }
    });

    const clientHeight = get(elem, 'clientHeight') || 0;

    const itemPosition = index * clientHeight;
    const scrollTo = itemPosition - clientHeight;

    console.log(elem.clientHeight, clientHeight, itemPosition, index);

    if (itemPosition + 20 >= x.clientHeight) {
      x.scrollTo({
        behavior: 'smooth',
        top: scrollTo,
      });
    }
  }
}
