import { AuthService } from "./../services/auth.service";
import { Validators } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import { FormBuilder } from "@angular/forms";
import { Component, OnInit, Inject } from "@angular/core";
import imageCompression from "browser-image-compression";
import { ToastrService } from "ngx-toastr";
import { pushBannerInfo } from "../../interfaces/pushBannerInfo.interface";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { PushBannerDeleteDialogboxComponent } from "./push-banner-delete-dialogbox/push-banner-delete-dialogbox.component";

@Component({
  selector: "app-push-banner",
  templateUrl: "./push-banner.component.html",
  styleUrls: ["./push-banner.component.scss"],
})
export class PushBannerComponent implements OnInit {
  bannerList: pushBannerInfo[] = [];
  bannerForm: FormGroup;
  uploadedBannerImg: string = "";
  bannerOptions = {
    maxSizeMB: 0.4,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };
  loading: boolean = false;
  isEdit: boolean = false;

  constructor(
    private FB: FormBuilder,
    private authService: AuthService,
    private toastService: ToastrService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.bannerForm = this.FB.group({
      displayTime: [3, [Validators.required, Validators.max(99)]],
      bannerTitle: ["", Validators.required],
      bannerLink: ["", Validators.required],
      bannerID: [""],
    });
    // this.bannerForm.valueChanges.subscribe((e) => {
    //   // console.log(this.bannerForm.get(["displayTime"]).errors);
    //   console.log(this.bannerForm.value)
    // });
    this.getBannerInfo();
  }

  openDialog(id: string, bannerTitle): void {
    const dialogRef = this.dialog.open(PushBannerDeleteDialogboxComponent, {
      maxWidth: "400px",
      data: {
        title: "banner delete",
        message: `Are you sure you want to delete banner`,
        bannerTitle: bannerTitle,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.authService.deleteData(`push_banner/${id}`).subscribe(
          (resp) => {
            console.log(resp);
            this.bannerList = this.bannerList.filter(
              (banner: pushBannerInfo) => banner._id !== id
            );
            this.toastService.success("success");
          },
          (err) => {
            console.log(err);
            this.toastService.error(err);
          }
        );
      }
      // this.animal = result;
    });
  }

  getBannerInfo() {
    this.authService.getData("push_banner").subscribe(
      (resp) => {
        // console.log(resp);
        this.bannerList = resp.bannerList.map((obj) => {
          return { ...obj, isPreview: false };
        });
        console.log(this.bannerList);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  onFileSelected(event): void {
    const bannerImage = event.target.files[0];
    let img = new Image();
    img.src = window.URL.createObjectURL(bannerImage);
    img.onload = async () => {
      // alert(img.width + " " + img.height);
      if (img.width === 1200 && img.height === 500) {
        this.loading = true;
        const compressedBannerImg: File = await imageCompression(
          bannerImage,
          this.bannerOptions
        );
        this.uploadFile(compressedBannerImg);
      } else {
        this.toastService.error("uplaod an image with resolution of 1200x500");
      }
    };
    // console.log(img);
    // validation for width and height
  }

  uploadFile(file: File) {
    const formData = new FormData();
    formData.append("file", file);

    this.authService.uploadFile("file", formData).subscribe(
      (resp) => {
        this.loading = false;
        this.uploadedBannerImg = resp.data.location;
      },
      (err) => {
        console.log(err);
        this.toastService.error(err);
      }
    );
  }

  removeBanner(imgURL: string) {
    this.uploadedBannerImg = "";
  }

  onPublish(value) {
    console.log(value);
    if (value.bannerTitle === "" || value.bannerLink === "") {
      this.toastService.error(
        `Please fill all the relevant fields before  ${
          !this.isEdit ? "publishing" : "updating"
        }`
      );
      return;
    }

    if (this.uploadedBannerImg === "") {
      this.toastService.error(
        `Please upload an image before ${
          !this.isEdit ? "publishing" : "updating"
        }`
      );
      return;
    }

    const data = {
      bannerImage: this.uploadedBannerImg,
      displayTime: value.displayTime,
      bannerTitle: value.bannerTitle,
      bannerLink: value.bannerLink,
    };

    if (!this.isEdit) {
      this.authService.postData("push_banner", data).subscribe(
        (resp: { data: pushBannerInfo }) => {
          this.bannerList.unshift(resp.data);
          this.uploadedBannerImg = "";
          this.toastService.success("successfully published");
          this.bannerForm.reset();
        },
        (err) => {
          this.toastService.error(err);
        }
      );
    } else {
      // console.log("im update");
      this.authService.putData(`push_banner/${value.bannerID}`, data).subscribe(
        (resp: { data: pushBannerInfo }) => {
          this.bannerList = this.bannerList.map((banner: pushBannerInfo) => {
            if (banner._id === value.bannerID) {
              return {
                ...banner,
                ...value,
                bannerImage: this.uploadedBannerImg,
              };
            }
            return banner;
          });
          this.uploadedBannerImg = "";
          this.isEdit = false;
          this.bannerForm.reset();
          this.toastService.success("successfully updated");
        },
        (err) => {
          this.toastService.error(err);
        }
      );
      // this.authService.
    }
  }

  onUpdate(id: string, statusData: { isActive: boolean }) {
    console.log(id, statusData);
    this.authService.patchData(`push_banner/${id}`, statusData).subscribe(
      (resp) => {
        console.log(resp);
        this.bannerList = this.bannerList.map((banner: pushBannerInfo) => {
          if (banner._id === id) {
            return {
              ...banner,
              ...statusData,
            };
          }
          return banner;
        });
        this.toastService.success("successfully updated the record");
      },
      (err) => {
        console.log(err);
        this.toastService.error(err);
      }
    );
  }

  onDelete(banner: pushBannerInfo): void {
    // alert(id);
    // console.log(bannerTitle);
    this.openDialog(banner._id, banner.bannerTitle);
    // this.authService.deleteData(`push_banner/${id}`).subscribe(
    //   (resp) => {
    //     console.log(resp);
    //     this.bannerList = this.bannerList.filter(
    //       (banner: pushBannerInfo) => banner._id !== id
    //     );
    //     this.toastService.success("success");
    //   },
    //   (err) => {
    //     console.log(err);
    //     this.toastService.error(err);
    //   }
    // );
  }

  onEdit(id: string) {
    this.isEdit = true;
    const bannerDetails: pushBannerInfo = this.bannerList.find(
      (banner: pushBannerInfo) => banner._id === id
    );
    const {
      _id: bannerID,
      bannerImage,
      bannerTitle,
      bannerLink,
      displayTime,
    } = bannerDetails;

    this.uploadedBannerImg = bannerImage;
    this.bannerForm.setValue({
      bannerTitle,
      bannerLink,
      displayTime,
      bannerID,
    });
  }
}
