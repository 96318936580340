import { Component, Input, OnDestroy, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'api-status',
  template: `<span>{{ status }}</span>`,
  encapsulation: ViewEncapsulation.None,
})
export class StatusComponent implements OnInit {
  public backgroundColor: string = '#ff6600';
  public color: string = 'white';
  @Input() public status: String;

  constructor(@Inject(DOCUMENT) private document: Document) {
    console.log(document);
  }

  ngOnInit() {
    console.log(this);
    if (this.status === 'deleted') {
      this.backgroundColor = 'red';
      this.color = 'white';
    }
  }
}
