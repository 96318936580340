// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

let environment = {
  production: false, 
  featuredListingURL: "https://devwp.construcshare.com/php/",
  featuredListingTOKEN:  "5ba5afb341128d4ed76661e9844f44eb80d09f4299b520f799edf9c176f967f6",
  apiBaseURL: 'http://api.dev.construcshare.com/api/v1/',
  socketBaseURL: 'http://api.dev.construcshare.com',
  firebase: {
    apiKey: 'AIzaSyDjz8oQf-W4Ys06AWYx9PRdXB9-glH1-rM',
    authDomain: 'constructshare-1d264.firebaseapp.com',
    databaseURL: 'https://constructshare-1d264-default-rtdb.firebaseio.com',
    projectId: 'constructshare-1d264',
    storageBucket: 'constructshare-1d264.appspot.com',
    messagingSenderId: '452870421853',
    appId: '1:452870421853:web:db47ea24eddd713bf77cb4',
    measurementId: 'G-2H1WFWCEWN',
  },


};
 
if (window.location.origin.startsWith('http://localhost')) {
  environment = {
    production: false,
    featuredListingURL: "https://devwp.construcshare.com/php/",
    featuredListingTOKEN:  "5ba5afb341128d4ed76661e9844f44eb80d09f4299b520f799edf9c176f967f6",
    apiBaseURL: 'http://localhost:5001/api/v1/',
    socketBaseURL: 'http://localhost:5001',
    firebase: {
      apiKey: 'AIzaSyDjz8oQf-W4Ys06AWYx9PRdXB9-glH1-rM',
      authDomain: 'constructshare-1d264.firebaseapp.com',
      databaseURL: 'https://constructshare-1d264-default-rtdb.firebaseio.com',
      projectId: 'constructshare-1d264',
      storageBucket: 'constructshare-1d264.appspot.com',
      messagingSenderId: '452870421853',
      appId: '1:452870421853:web:db47ea24eddd713bf77cb4',
      measurementId: 'G-2H1WFWCEWN',
    },
   
  };
}

export { environment };