import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
  selector: "app-push-banner-delete-dialogbox",
  templateUrl: "./push-banner-delete-dialogbox.component.html",
  styleUrls: ["./push-banner-delete-dialogbox.component.css"],
})
export class PushBannerDeleteDialogboxComponent implements OnInit {
  title: string;
  bannerTitle: string;
  message: string;

  constructor(
    public dialogRef: MatDialogRef<PushBannerDeleteDialogboxComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.title = data.title;
    this.message = data.message;
    this.bannerTitle = data.bannerTitle;
  }

  ngOnInit() {}

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }
}
