import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { ToastrService } from 'ngx-toastr';

import { customValidator } from '../../shared/custom.form.validation';
import { RegExpValidator } from '../../shared/regexp-validators';
import { Criteria } from '../../shared/critaria';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  loginForm: FormGroup;
  loginFormErrors: any;
  token;
  otp: string;
  step: number = 1;
  loading = false;

  constructor(
    public activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private toast: ToastrService
  ) {
    this.loginFormErrors = {
      password: {},
      cpassword: {},
    };
  }

  ngOnInit() {
    // this.sendOtp();
    this.loginForm = this.formBuilder.group({
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          customValidator(RegExpValidator.lowerCase, Criteria.at_least_one_lower_case_char),
          customValidator(RegExpValidator.upperCase, Criteria.at_least_one_upper_case_char),
          customValidator(RegExpValidator.digit, Criteria.at_least_one_digit_char),
          customValidator(RegExpValidator.specialChar, Criteria.at_least_one_special_char),
        ],
      ],
      cpassword: ['', [Validators.required]],
    });

    // this.loginForm.controls['password'].valueChanges.subscribe((v) => {});
  }

  handleBack(e) {
    this.step = 2;
  }

  sendOtp() {
    this.loading = true;
    const email = localStorage.getItem('email');
    this.authService.postData('user/admin/forgotPassword', { email }).subscribe(
      (res) => {
        console.log(res);
        if (res.status == 'success') {
          this.toast.success('OTP Sent To Your Email Successfully');
        }
        this.loading = false;
        this.step = 2;
      },
      (err) => {
        this.loading = false;
        this.toast.error(err);
      }
    );
  }

  onOtpSubmit() {
    this.step = 3;
  }

  onLoginFormValuesChanged() {
    for (const field in this.loginFormErrors) {
      if (!this.loginFormErrors.hasOwnProperty(field)) {
        continue;
      }

      this.loginFormErrors[field] = {};

      const control = this.loginForm.get(field);

      if (control && control.dirty && !control.valid) {
        this.loginFormErrors[field] = control.errors;
      }
    }
  }

  save(data) {
    this.loading = true;
    this.authService.postData('user/resetPassword/' + this.otp, data).subscribe(
      (response) => {
        if (response.status === 'success') {
          this.loading = false;
          this.router.navigateByUrl('/login');
          this.toast.success('password reset successfully...');
        }
      },
      (err) => {
        this.loading = false;
        this.toast.error(err);
      }
    );
  }
}
