import { Component, OnInit } from "@angular/core";
import { PageEvent } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { get } from "lodash";
import { BuyerRequest } from "../../interfaces/request.interface";
import { AuthService } from "../services/auth.service";

@Component({
  selector: "app-web-request",
  templateUrl: "./web-request.component.html",
  styleUrls: ["./web-request.component.css"],
})
export class WebRequestComponent implements OnInit {
  displayedColumns: string[] = [
    "requestId",
    "title",
    "types",
    "category",
    "createdAt",
    "userId",
    "anonymousUserId",
    "userName",
    "userEmail",
    "userPhoneNumber",
    "status",
    "Action",
    "description",
  ];

  dataSource: BuyerRequest[] = [];
  totalEntries: number = 0;
  page: number = 1;
  pageSize: number = 10;
  pageEvent?: PageEvent;
  searchText: string;
  sort: string;
  status: string[] = ["approved", "deleted", "review"];

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.getRequests();
  }

  getRequests() {
    this.authService
      .getData("buyer_webform/admin", {
        page: this.page,
        limit: this.pageSize,
        search: this.searchText,
        sort: this.sort,
        status: this.status.join(","),
      })
      .subscribe((data) => {
        this.dataSource = get(data, "data.entries", []);
        console.log(this.dataSource);
        this.totalEntries = get(data, "data.count", 0);
      });
  }
  onChangeSearch(event) {
    this.page = 1;
    this.searchText = event.target.value;
    this.getRequests();
  }

  onSortChange(event) {
    console.log(event);
    if (event.direction) {
      this.sort = `${event.active},${event.direction}`;
    } else {
      this.sort = "";
    }
    this.getRequests();
  }

  onPageChange(event?: PageEvent): void {
    this.page = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.getRequests();
  }

  //handle approve
  handleApprove(id, element) {
    console.log(id, element);

    //update the anonymouswebrequest database status use put
    const result = confirm("Are you sure?");
    if (result) {
      this.authService
        .patchData(`buyer_webform/single/${id}`, {
          status: "approved",
        })
        .subscribe((data) => {
          element.status = "approved";
          // if (data.status === "success") {
          //   console.log(data.result);

          //   //create a new buyer request with status pre approved
          //   this.authService
          //     .postData("buyer_request", data.result)
          //     .subscribe((data) => {
          //       console.log(`post data : ${data}`)
          //     });
          // }
        });
    }
  }

  //handle delete
  handleDelete(id, element) {
    const result = confirm('Are you sure?');
    if (result) {
      this.authService.deleteData(`buyer_webform/single/${id}`).subscribe((data) => {
        element.status = 'approved';
      });
    }
  }

  //handle edit

  handleChangeStatus(value) {
    this.status = value;
    this.getRequests();
  }
}
