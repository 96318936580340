import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { MediaMatcher } from "@angular/cdk/layout";
import { MenuItems } from "../../../shared/menu-items/menu-items";
import { AuthService } from "../../../services/auth.service";
import { Router } from "@angular/router";
import { SocketService } from "../../../services/socket.service";
import { get } from "lodash";
import { HelpDesk } from "../../../../interfaces/helpdesk.interface";
import { MessageService } from "../../../services/message.service";
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: [],
})
export class AppSidebarComponent implements OnDestroy, OnInit {
  public config: PerfectScrollbarConfigInterface = {};
  mobileQuery: MediaQueryList;
  userName;

  private _mobileQueryListener: () => void;
  status: boolean = true;

  itemSelect: number[] = [];

  subclickEvent() {
    this.status = true;
  }

  totalUnread = 0;
  totalUnreviewed;
  dataSource;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private authService: AuthService,
    public menuItems: MenuItems,
    private router: Router,
    public socketService: SocketService,
    public messageService: MessageService
  ) {
    this.mobileQuery = media.matchMedia("(min-width: 768px)");
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.userName = localStorage.getItem("firstName");
    this.fetchChats();
    this.fetchReviews();
  }

  Logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    localStorage.removeItem("addCoworker");
    localStorage.removeItem("coworkerData");
    localStorage.removeItem("addUser");
    localStorage.removeItem("allUserData");
    localStorage.removeItem("addProduct");
    localStorage.removeItem("allProductData");
    localStorage.removeItem("addProductCategory");
    localStorage.removeItem("allProductCategoryData");
    localStorage.removeItem("addProductSubCategory");
    localStorage.removeItem("allProductSubCategoryData");
    localStorage.removeItem("addEmailTemplate");
    localStorage.removeItem("allTemplateData");
    localStorage.removeItem("addDispute");
    localStorage.removeItem("allDisputeData");
    localStorage.removeItem("allAuditLogData");
    this.router.navigate(["/"]);
  }

  change() {
    this.router.navigate(["/reset"]);
  }

  ngOnInit(): void {
    this.socketService.createRoom("ADMIN");
    this.socketService.listen("ADMIN_BUYER_REQ_COUNT").subscribe((data:{count:Number}) => {
      // const count = data['count'];
       
      this.totalUnreviewed = data.count;
      console.log(data.count);

      // alert(JSON.stringify(data));
    });

    this.socketService
      .listen("HELP_DESK")
      .subscribe((data: { chats: HelpDesk }) => {
        const chat: HelpDesk = get(data, "data.chats");
        this.messageService.sendMessage("UPDATE_CONTACT", chat);
        if (chat.adminUnread > 0) {
          this.totalUnread += 1;
        }
      });

    this.messageService.onMessage.subscribe((e) => {
      if (e.topic === "CHAT_COUNT_RESET") {
        this.totalUnread -= Number(e.data);
      }
    });

    // this.socketService.createRoom("TEST");
  }

  fetchChats(): void {
    this.authService.getData("help_desk/contacts").subscribe((res) => {
      if (res.data.chats.length) {
        for (let i = 0; i < res.data.chats.length; i++) {
          if (res.data.chats[i].adminUnread) {
            this.totalUnread += res.data.chats[i].adminUnread;
          }
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  fetchReviews(): void {
    this.authService.getData("buyer_request/admin").subscribe((data) => {
      this.dataSource = get(data, "data.reviewCount");
      this.totalUnreviewed = this.dataSource;
    });
  }
}

// getRequests() {
//   this.authService
//     .getData('buyer_request/admin', {
//       page: this.page,
//       limit: this.pageSize,
//       search: this.searchText,
//       sort: this.sort,
//       status: this.status.join(','),
//     })
//     .subscribe((data) => {
//       this.dataSource = get(data, 'data.entries', []);
//       this.totalEntries = get(data, 'data.count', 0);
//     });
// }
