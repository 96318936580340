import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  saperator?: Saperator[];
  children?: ChildrenItems[];
}

const MENUITEMS = [
  {
    state: 'dashboard',
    name: 'Dashboard',
    type: 'link',
    icon: 'dashboard',
  },
  {
    state: 'user',
    name: 'Users',
    type: 'link',
    icon: 'group',
  },
  {
    state: 'coworkers',
    name: 'Company & Coworkers',
    type: 'link',
    icon: 'supervisor_account',
  },
  {
    state: 'product',
    name: 'Product',
    type: 'link',
    icon: 'category',
  },
  {
    state: 'orders',
    name: 'Orders',
    type: 'link',
    icon: 'category',
  },
  {
    state: 'chat',
    name: 'Help Desk',
    type: 'link',
    icon: 'chat',
  },
  {
    state: 'payment',
    name: 'Payment',
    type: 'link',
    icon: 'payments',
  },
  // {
  //   state: 'dispute',
  //   name: 'Dispute',
  //   type: 'link',
  //   icon: 'gpp_bad',
  // },
  {
    state: 'performance',
    name: 'Performance',
    type: 'link',
    icon: 'directions_run',
  },
  {
    state: 'buyer-request',
    name: 'Buyer Request',
    type: 'link',
    icon: 'party_mode',
  },
  {
    state: 'web-request',
    name: 'Web Request',
    type: 'link',
    icon: 'party_mode',
  },
  {
    state: 'notification',
    name: 'Notifications',
    type: 'link',
    icon: 'notifications',
  },
  {
    state: 'review-rating',
    name: 'Review & Rating',
    type: 'link',
    icon: 'reviews',
  },
  {
    state: 'email-template',
    name: 'Email-Template',
    type: 'link',
    icon: 'email',
  },
  // {
  //   state: 'user-product-report',
  //   name: 'Reports',
  //   type: 'sub',
  //   icon: 'report',
  //   badge: [],
  //   children: [
  //     { state: 'user-blacklist-report', name: 'User Blacklist', type: 'link' },
  //     {
  //       state: 'product-blacklist-report',
  //       name: 'Product Blacklist',
  //       type: 'link',
  //     },
  //   ],
  // },
  // {
  //   state: 'customise-report',
  //   name: 'Customised Report',
  //   type: 'link',
  //   icon: 'support_agent',
  // },
  {
    state: 'category',
    name: 'Category',
    type: 'link',
    icon: 'category',
  },
  {
    state: 'audit-log',
    name: 'Audit Log',
    type: 'link',
    icon: 'lock',
  },
  // {
  //   state: 'plan',
  //   name: 'Plan',
  //   type: 'link',
  //   icon: 'folder',
  // },
  {
    state: 'interest',
    name: 'Interest',
    type: 'link',
    icon: 'library_books',
  },
  {
    state: 'settings',
    name: 'App Master Setting',
    type: 'link',
    icon: 'settings',
  },
  {
    state: 'reset-password',
    name: 'Change Password',
    type: 'link',
    icon: 'key',
  },
  {
    state: 'push-info',
    name: 'Push Info',
    type: 'link',
    icon: 'notifications',
  },
  {
    state: 'push-banner',
    name: 'Push Banner',
    type: 'link',
    icon: 'picture_in_picture',
  },
];

@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }
}
