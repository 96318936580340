import { numberFormat } from "highcharts";

export class Mixin {
  getFilenameFromURL(url) {
    return /[^/]*$/.exec(url);
  }

  formatDate(date: string) {
    return new Date(date).toLocaleString('en-US', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });
  }

  formatPrice(price) {
    return 'SGD ' + parseFloat(price ? price : 0).toFixed(2);
  }
  formatPrice2(price) {
    return '' + parseFloat(price ? price : 0).toFixed(0);
  }

  formatDateTime(date) {
    return new Date(date).toLocaleString('en-US', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  }

  formatTime(date) {
    return new Date(date).toLocaleString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  }

  generateCsv(data: any, filename?: string) {
    const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    const csv = data.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');

    const a = document.createElement('a');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = `${filename || 'untitled'}.csv`;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
}
