import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { get } from 'lodash';
import { BuyerRequest } from '../../interfaces/request.interface';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-buyer-request',
  templateUrl: './buyer-request.component.html',
  styleUrls: ['./buyer-request.component.scss'],
})
export class BuyerRequestComponent implements OnInit {
  displayedColumns: string[] = [
    'requestId',
    'title',
    'types',
    'category',
    'createdAt',
    'userId',
    'userName',
    'userEmail',
    'userPhoneNumber',
    'status',
    'Action',
    'description'

  ];

  dataSource: BuyerRequest[] = [];
  totalEntries: number = 0;
  page: number = 1;
  pageSize: number = 10;
  pageEvent?: PageEvent;
  searchText: string;
  sort: string;
  status: string[] = ['approved', 'deleted', 'review'];

  constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.getRequests();
  }

  getRequests() {
    this.authService
      .getData('buyer_request/admin', {
        page: this.page,
        limit: this.pageSize,
        search: this.searchText,
        sort: this.sort,
        status: this.status.join(','),
      })
      .subscribe((data) => {
        this.dataSource = get(data, 'data.entries', []);
        this.totalEntries = get(data, 'data.count', 0);
      });
  }

  onChangeSearch(event) {
    this.page = 1;
    this.searchText = event.target.value;
    this.getRequests();
  }

  onSortChange(event) {
    console.log(event);
    if (event.direction) {
      this.sort = `${event.active},${event.direction}`;
    } else {
      this.sort = '';
    }
    this.getRequests();
  }

  onPageChange(event?: PageEvent): void {
    this.page = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.getRequests();
  }

  handleApprove(id, element) {
    const result = confirm('Are you sure?');
    if (result) {
      this.authService
        .patchData(`buyer_request/single/${id}`, {
          status: 'approved',
        })
        .subscribe((data) => {
          element.status = 'approved';
        });
    }
  }
  handleEdit(id, element) {
    // this.router.navigate([`/buyer-request/edit/${id}`]);

    this.router.navigate(['./edit', id], { relativeTo: this.route, state: { data: element } });
  }
  handleDelete(id, element) {
    const result = confirm('Are you sure?');
    if (result) {
      this.authService.deleteData(`buyer_request/single/${id}`).subscribe((data) => {
        element.status = 'deleted';
      });
    }
  }

  handleChangeStatus(value) {
    this.status = value;
    this.getRequests();
  }
}
