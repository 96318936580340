import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PushInfo } from '../../interfaces/pushinfo.interface';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-push-info',
  templateUrl: './push-info.component.html',
  styleUrls: ['./push-info.component.scss'],
})
export class PushInfoComponent implements OnInit {
  intialState = {
    web: false,
    mobile: false,
    email: false,
    message: '',
  };

  state = {
    web: this.intialState.web,
    mobile: this.intialState.mobile,
    email: this.intialState.email,
    message: this.intialState.message,
  };

  loading: boolean = false;

  pushInfos: PushInfo[] = [];

  constructor(private authService: AuthService, private toastService: ToastrService) {}

  @ViewChild('msgBox', { static: true }) msgBox: ElementRef;

  ngOnInit(): void {
    this.getPushInfos();
  }

  onChangeMsg(event) {
    this.state.message = event.target.innerHTML;
  }

  getPushInfos() {
    this.authService.getData('push_info').subscribe((response: { data: { entries: PushInfo[] } }) => {
      console.log(response);
      this.pushInfos = response.data.entries;
    });
  }

  create(e) {
    console.log(this.state);
    this.loading = true;
    this.authService.postData('push_info', this.state).subscribe(
      (response: { data: PushInfo }) => {
        this.pushInfos.unshift(response.data);
        this.state = { ...this.intialState, message: '' };
        this.loading = false;
        this.toastService.success('One Record Added Successfully');
        this.msgBox.nativeElement.innerHTML = '';
      },
      (err) => {
        console.log('Error on creating system message', err);
        this.toastService.error(err);
        this.loading = false;
      }
    );
  }

  update(id, value, shouldUpdate = true) {
    if (shouldUpdate) {
      this.authService.patchData(`push_info/${id}`, value).subscribe(
        (response: { data: PushInfo }) => {
          console.log(response);
          this.pushInfos = this.pushInfos.map((p) => {
            if (p._id === id) {
              return {
                ...p,
                ...value,
              };
            }
            return p;
          });
          this.toastService.success('One Record Updated Successfully');
        },
        (err) => {
          this.toastService.error(err);
          console.log('Error on updating system message', err);
        }
      );
    }
  }

  delete(id) {
    const result = confirm('Do you want to delete');
    if (result) {
      this.authService.deleteData(`push_info/${id}`).subscribe(
        () => {
          this.pushInfos = this.pushInfos.filter((p) => p._id !== id);
          this.toastService.success('One Record Deleted Successfully');
        },
        (err) => {
          this.toastService.error(err);
          console.log('Error on deleting system message', err);
        }
      );
    }
  }
}
