import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatListOption, MatSelectionList, PageEvent } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { get } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Post } from '../../../interfaces/post.interface';
import { BuyerRequest } from '../../../interfaces/request.interface';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-buyer-request',
  templateUrl: './buyer-request-edit.component.html',
  styleUrls: ['./buyer-request-edit.component.scss'],
})
export class BuyerRequestEditComponent implements OnInit {
  request: BuyerRequest;
  posts: Post[];
  formId: string;

  allCategories;

  @ViewChild(MatSelectionList, { static: true })
  private selectionList: MatSelectionList;

  displayedColumns: string[] = ['postTitle', '_id', 'userName', 'userId', 'companyName'];

  constructor(
    private authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private toastService: ToastrService
  ) {
    this.request = get(this.router.getCurrentNavigation(), 'extras.state.data');
  }

  formGroup = this.formBuilder.group({
    title: ['', Validators.required],
    description: ['', Validators.required],
    category: ['', Validators.required],
    types: [[], [Validators.required, Validators.maxLength(2)]],
  });

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      var id = params.get('id');
      this.formId = id;
      if (id) {
        this.getRequest();
      }
    });

    this.authService.getData('category/getAllCategory').subscribe((res) => {
      this.allCategories = res.data;
    });

    this.selectionList.selectedOptions = new SelectionModel<MatListOption>(false);
    this.selectionList.selectedOptions.changed.subscribe((e) => {
      this.formGroup.get(['category']).patchValue(this.selectionList.selectedOptions.selected[0].value);
    });
  }

  getRequest() {
    this.patchValue();
    this.authService.getData(`buyer_request/single/${this.formId}`).subscribe((data) => {
      this.request = get(data, 'result');
      this.posts = get(data, 'posts');
      this.patchValue();
    });
  }

  patchValue() {
    if (this.request) {
      this.formGroup.patchValue({
        title: get(this.request, 'title'),
        description: get(this.request, 'description'),
        category: get(this.request, 'category'),
        types: get(this.request, 'types'),
      });
    }
  }

  handleChangeType(e, name) {
    let types = this.formGroup.value.types;
    if (e.checked) {
      types.push(name);
    } else {
      types = types.filter((type) => type !== name);
    }

    this.formGroup.patchValue({
      types,
    });
  }

  onSubmit(values) {
    console.log(values);
    this.authService.setLoader(true);
    this.authService.patchData(`buyer_request/single/${this.formId}`, values).subscribe(
      (data) => {
        this.toastService.success('Updated Successfully');
        this.router.navigate(['/buyer-request']);
        this.authService.setLoader(false);
      },
      (err) => {
        this.authService.setLoader(false);
      }
    );
  }

  onErrorImage(e) {
    e.src = '/assets/images/logo.png';
  }
}
